;(function( window, document, $, undefined ) {
  'use strict';

  var home = (function() {

    var $private = {};
    var $public = {};
    var screen_width = $(window).width();

    $public.accordionBanner = function() {
      var contWidth = $('.dd-accordion-horizontal').width();
      var numPanels = ($('.dd-panel').length - 1) * 31;
      var activePanel = $('.dd-accordion-horizontal li.dd-panel:first');

      //$(activePanel).addClass('active').css({width: (contWidth - numPanels) +'px'});
      $(activePanel).addClass('active').css('width', '100%').css('width', '-='+numPanels+'px');
      $('.dd-panel-content').css({width: contWidth - numPanels+'px'});

      $(window).resize(function(){
        contWidth = $('.dd-accordion-horizontal').width();
        $(activePanel).addClass('active').css('width', '100%').css('width', '-='+numPanels+'px');
        $('.dd-panel-content').css({width: contWidth - numPanels+'px'});
      });



      $(".dd-accordion-horizontal").delegate('.dd-panel', 'click', function(e){
        if( ! $(this).is('.active') ){
    			$(activePanel).animate({width: "30.8px"}, 300);

          $(this).animate({width: contWidth - numPanels+'px'}, 300);
    			$('.dd-accordion-horizontal .dd-panel').removeClass('active');
    			$(this).addClass('active');
    			activePanel = this;
		    };
      });
    };

    $public.sliderLogos = function () {
      var sliderLogos = $('.dd-logo-list').bxSlider({
        slideWidth: 95,
        minSlides: 1,
        maxSlides: 9,
        slideMargin: 18,
        pager: false,
        controls: true,
        infiniteLoop : false,
        hideControlOnEnd : true,
        onSliderLoad : function() {
          $('.dd-logo-list').css('visibility' , 'visible');
        }
      });

      $(window).resize(function(){
        sliderLogos.reloadSlider();
      });

    };

    return $public;
  })();

  // Global
  window.home = home;
  home.accordionBanner();
  home.sliderLogos();

})( window, document, jQuery );

$(window).load(function() {
});
